import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/src/components/MDXLayout.tsx";
import SEO from 'components/SEO';
import CauseHero from 'components/CauseHero';
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query BanyUmmaty {
    heroImage: file(relativePath: {eq: "project-bany-hero.png"}) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Bany UMMATY: Orphans care .. education for all.. to build a better future." description="BANY UMMATY program is an opportunity offered to you to be amongst the ones that the prophet peace and blessings be upon him has described, by standing beside orphans and taking care of them." mdxType="SEO" />
    <CauseHero purpose="bany-ummaty" title="Bany UMMATY" text="Orphans care .. education for all.. to build a better future." data={props.data} pagePath={props.path} mdxType="CauseHero" />
    <div className="p-wrapper">
      <h2>{`Umma's Orphans care`}</h2>
      <p>{`The Prophet, peace and blessings be upon him, said, « `}<strong parentName="p">{`The one who cares for an orphan and myself will be together in Paradise like this`}</strong>{` » and he held his two fingers together to illustrate.`}</p>
      <p>{`BANY UMMATY program is an opportunity offered to you to be amongst the ones that the prophet peace and blessings be upon him has described, by standing beside orphans and taking care of them. BANY UMMATY program includes `}<strong parentName="p">{`construction and full equipment of orphans houses`}</strong>{` (including dormitory rooms, classes, libraries, bathrooms, playgrounds, etc.). We also `}<strong parentName="p">{`renovate existing damaged facilities`}</strong>{`, and provide them with `}<strong parentName="p">{`furniture`}</strong>{` and support to run these facilities throughout the year.`}</p>
      <h2>{`Education for all`}</h2>
      <p>{`Through the BANY UMMATY program we also support education for the children of our ummah. Our major projects include `}<strong parentName="p">{`building new schools`}</strong>{`, with all required facilities and equipment. Smaller functional projects include direct `}<strong parentName="p">{`support to needy children`}</strong>{` to enable them to continue their education. This includes procuring and distributing `}<strong parentName="p">{`school furniture`}</strong>{`, school bags and all necessary items to support their education. These kids are the children of our ummah. With your support we are able to help them build a better future.`}</p>
    </div>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      